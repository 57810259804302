<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400" >
       <!-- <h1>Política de privacidad</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/pcainfoyserinte/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`
Nuestra empresa se compromete a garantizar que su privacidad esté protegida. Si le pedimos que nos proporcione cierta información mediante la cual pueda ser identificado al utilizar este sitio web, puede estar seguro de que solo se utilizará de acuerdo con esta declaración de privacidad.

Nuestra empresa puede cambiar esta política cuando actualice su sitio web. Debe visitar esta página de vez en cuando para asegurarse de que está satisfecho con los cambios. Esta política entrará en vigor en febrero de 2004.

Nuestro requisito

Podemos recopilar la siguiente información:

Nombre y título
Información de contacto, incluida la dirección de correo electrónico
Información demográfica como código postal, preferencias e intereses.
Otra información relacionada con encuestas a clientes, ofertas y/o promociones
¿Qué hacemos con la información que recopilamos?

Requerimos esta información para comprender sus necesidades y brindarle un mejor servicio, y en particular por las siguientes razones:

Mantener registros internos.
Podemos utilizar esta información para mejorar nuestros productos y servicios.
Podemos utilizar la dirección de correo electrónico que usted proporciona para enviar correos electrónicos promocionales sobre nuevos productos, ofertas especiales u otra información que creemos que podría interesarle.
De vez en cuando, también podemos utilizar su información para comunicarnos con usted para realizar estudios de mercado.
Podemos comunicarnos con usted por correo electrónico, teléfono, fax o correo postal.
Podemos utilizar esta información para personalizar el sitio web según sus intereses.

Seguridad
Estamos comprometidos a garantizar la seguridad de su información. Para evitar el acceso o la divulgación no autorizados, hemos implementado procedimientos físicos, electrónicos y administrativos para salvaguardar y asegurar la información que recopilamos en línea.

Cómo usamos las cookies?

Una cookie es un pequeño archivo que solicita permiso para ser colocado en el disco duro de su computadora. Una vez que esté de acuerdo, el archivo se agrega y la cookie ayuda a analizar el tráfico web o le permite saber cuándo visita un sitio en particular. Las cookies permiten que las aplicaciones web lo identifiquen personalmente. Las aplicaciones web pueden adaptar sus operaciones a sus necesidades, gustos y aversiones recopilando o recordando información sobre sus preferencias.
Utilizamos cookies de registro de tráfico para identificar qué páginas se están utilizando. Esto nos ayuda a analizar datos sobre el tráfico web y mejorar nuestro sitio web para satisfacer las necesidades de los clientes. Sólo utilizamos esta información con fines de análisis estadístico y luego los datos se eliminarán del sistema.
En general, las cookies nos ayudan a ofrecerle un mejor sitio web al permitirnos monitorear qué páginas le resultan útiles y cuáles no. Una cookie de ninguna manera nos da acceso a su computadora ni a ninguna información sobre usted, aparte de los datos que elige compartir con nosotros.
Puede elegir aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan cookies automáticamente, pero usted puede modificar la configuración de su navegador para rechazarlas si lo prefiere. Esto puede impedirle aprovechar el sitio web.

Enlaces a otros sitios web

Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin embargo, una vez que haya utilizado estos enlaces para abandonar nuestro sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de la información que usted proporcione mientras visita estos sitios y estos sitios no se rigen por esta declaración de privacidad. Debe tener precaución y leer la declaración de privacidad aplicable al sitio web correspondiente.

Controla tu información personal

Puede optar por limitar la recopilación o el uso de su información personal mediante:

Cuando se le solicite que complete un formulario en el sitio web, busque un cuadro en el que pueda hacer clic para indicar que no desea que nadie utilice la información con fines de marketing directo.
Si previamente aceptó que utilicemos su información personal con fines de marketing directo, puede cambiar de opinión en cualquier momento por escrito o por correo electrónico.
No venderemos, distribuiremos ni cederemos su información personal a terceros a menos que tengamos su permiso o lo exija la ley. Podemos utilizar su información personal para enviarle información promocional sobre terceros que creemos que puede resultarle interesante si nos dice que desea que esto suceda.

Si cree que cualquier información que tenemos sobre usted es incorrecta o está incompleta, escriba o envíe un correo electrónico lo antes posible a la dirección anterior. Cualquier información incorrecta será corregida lo antes posible.

Política de privacidad de socios de Google
Reconocemos que la privacidad de su información personal es importante. Aquí puede encontrar los tipos de información personal que recibimos y recopilamos cuando utiliza y visita nuestro sitio web y nuestros esfuerzos para proteger su información. Nunca venderemos su información personal a terceros.

archivo de registro

Como la mayoría de los sitios web, solicitamos archivos de registro.

Los datos contenidos en el archivo de registro. La información contenida en los archivos de registro incluye su dirección IP (Protocolo de Internet), su ISP (Proveedor de servicios de Internet, como AOL o Prodigy), el navegador que utilizó para acceder a nuestro sitio web (como Internet Explorer o Firefox, Chrome, etc. ). ), la hora a la que nos visita y qué páginas de nuestro sitio web visita.

Cookies y contadores de visitantes.

Utilizamos cookies para almacenar información como sus preferencias personales cuando visita nuestro sitio web. Esto puede incluir mostrar únicamente ventanas emergentes al visitar nuestro sitio web o la capacidad de acceder a algunas de nuestras herramientas, como foros y contadores de visitantes.

Algunos de estos anunciantes pueden utilizar tecnologías como cookies y balizas web cuando se anuncian en nuestro sitio, lo que enviará al anunciante (como Google a través del programa AdSense) información que incluye su dirección IP, su ISP, el navegador que utiliza para acceder a nuestro sitio web. sitio y, en algunos casos, si tiene Flash instalado.

Esto se utiliza a menudo con fines de orientación geográfica (por ejemplo, mostrar anuncios de bienes raíces a personas que viven en una determinada ciudad) o para mostrar ciertos anuncios basados en sitios web específicos visitados (por ejemplo, mostrar anuncios de cocina a personas que frecuentan sitios web de cocina).

  Cookie DART de doble clic

También podemos utilizar cookies de DART (Informes y orientación de anuncios dinámicos) para publicar anuncios a través de DoubleClick de Google, que DoubleClick coloca en su computadora cuando navega por la web y visita sitios que usan anuncios de DoubleClick, incluidos algunos anuncios de Google AdSense. Esta cookie se utiliza para mostrarle publicidad específica para usted y sus intereses ("Segmentación por intereses"). Los anuncios se seleccionan en función de su historial de navegación anterior (por ejemplo, si ha visitado un sitio web sobre Las Vegas, es posible que vea anuncios relacionados con Las Vegas cuando visite un sitio web no relacionado).

DART utiliza información "no identificable personalmente" y no rastrea información personal como nombre, correo electrónico, dirección física, número de teléfono, número de Seguro Social, cuenta bancaria o número de tarjeta de crédito.

Puede optar por no recibir este servicio en todos los sitios web que utilizan este tipo de publicidad visitando nuestra información de URL

Puede optar por desactivar o desactivar selectivamente nuestras cookies o las de terceros en la configuración de su navegador, o administrando las preferencias en programas como Norton Internet Security. Sin embargo, esto puede afectar su capacidad para interactuar con nuestro sitio o cualquier otro sitio web. Esto puede incluir no poder acceder a servicios o programas, como iniciar sesión en foros o cuentas.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  /* background-color: #FFFFFF; */
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 /* margin-left: 490px; */
 color: #FFFFFF;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
/* display: inline-block; */
text-align: center;
}
.textBox{
  /* margin-top: 50px; */
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  /* transform: scale(98%); */
  /* transform: scale(86%); */
  padding: 0 100px;
  margin: -20px auto 200px;
  height: 3700px;
  /* box-shadow:0 3px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 270px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #333333FF;
  /* margin: 0 490px; */
  border-radius: 12px;
}
.img1{
  margin-top: 50px;
  /* padding: 0 200px; */
  /* background-color: #333333FF;
  height: 300px; */
}
.img2{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img3{
  /* background-image: url("@/page/pcainfoyserinte/components/img/4_bg.png"); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 100px 300px; */
}
</style>