var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox min-w-1400"},[_c('div',{staticClass:"imgBox1 min-w-1400"},[_c('div',{staticClass:"img1Box max-w-1400"},[_c('img',{staticClass:"imgItem1",attrs:{"src":_vm.importedImg1,"alt":""}}),_c('div',{staticClass:"clickBox1",on:{"click":function($event){return _vm.gotoPage('/')}}}),_c('div',{staticClass:"clickBox2",on:{"click":function($event){return _vm.gotoPage('/about')}}}),_c('div',{staticClass:"clickBox3",on:{"click":_vm.gotoTop}}),_c('div',{staticClass:"clickBox4",on:{"click":_vm.gotoLink}})])]),_c('div',{staticClass:"imgBox2 min-w-1400"},[_c('div',{staticClass:"img2Box max-w-1400"},[_c('img',{staticClass:"imgItem2",attrs:{"src":_vm.importedImg2,"alt":""}}),_c('div',{staticClass:"clickBox6",on:{"click":function($event){return _vm.gotoPDF('Licencia de la Compañía')}}}),_c('div',{staticClass:"clickBox7",on:{"click":function($event){return _vm.gotoPDF('Licencia de Servicios Financieros')}}}),_c('div',{staticClass:"clickBox8",on:{"click":function($event){return _vm.gotoPDF('Documentos de Autorización de la Compañía')}}})])]),_c('div',{staticClass:"imgBox3 min-w-1400"},[_c('div',{staticClass:"img3Box max-w-1400"},[_c('img',{staticClass:"imgItem3",attrs:{"src":_vm.importedImg3,"alt":""}})])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox4 min-w-1400"},[_c('div',{staticClass:"img4Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox5 min-w-1400"},[_c('div',{staticClass:"img5Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox6 min-w-1400"},[_c('div',{staticClass:"img6Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox7 min-w-1400"},[_c('div',{staticClass:"img7Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox8 min-w-1400"},[_c('div',{staticClass:"img8Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox9 min-w-1400"},[_c('div',{staticClass:"img9Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox10 min-w-1400"},[_c('div',{staticClass:"img10Box max-w-1400"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox11 min-w-1400"},[_c('div',{staticClass:"img11Box max-w-1400"})])
}]

export { render, staticRenderFns }